import React, { useState } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import Obfuscate from 'react-obfuscate'
import ButtonLink from '../components/common/button-link'
import MainCTA from '../components/sections/main-cta'

// import ObfuscateEmail from '../components/common/obfuscate-email'
import Seo from '../components/seo'
import Layout from '../components/layout'
import SocialIcons from '../components/common/social-icons'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Form, Button, Alert } from 'react-bootstrap'


const ContactPage = () => {
  const [validated, setValidated] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const [fnameValue, setFnameValue] = useState('')
  const [lnameValue, setLnameValue] = useState('')
  const [msgValue, setMsgValue] = useState('')
  const [formDisabled, setFormDisabled] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  const processForm = e => {

    e.preventDefault()
    e.stopPropagation()

    const form = e.currentTarget

    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
      setValidated(true)
      return
    }
    setValidated(true)
    setFormDisabled(true)

    fetch('https://manage.kmail-lists.com/ajax/subscriptions/subscribe', {
      headers: {
        accept: 'application/json, text/plain, */*',
        'content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        g: 'VLssJ6',
        $fields: 'first_name, last_name, Phone, zip, $source',
        email: emailValue,
        first_name: fnameValue,
        last_name: lnameValue,
        $source: 'Contact Form',
      }),
      method: 'POST',
    })
      .then(() => {
        setShowAlert(true)
      })
      .catch(err => {
        console.error(err)
      })

    if (window.klaviyo) {
      window.klaviyo.push(['track', 'Submitted Contact Form'])
    }


    //Gorgias;

    fetch('/.netlify/functions/gorgias-handler', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: emailValue,
        firstName: fnameValue,
        lastName: lnameValue,
        message: msgValue,
        subject: `Contact form submission from ${fnameValue} ${lnameValue}`,
        team: 2330,
      })
    })
      .then(response => response.json())
      .then(data => {
        //console.log(data);
        setShowAlert(true);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  return (
    <Layout>
      <Seo title="Contact" />
      <header className="text-center text-md-start">
        <Container fluid className="px-0">
          <Row>
            <Col md={{ span: 7, order: 1 }} lg="8" xxl="9">
              <Card className="p-2 border-0 px-lg-4 px-xl-6 px-xl py-lg-3 contact-form mx-auto">
                <h1 className="mb-1 mb-md-2 font-swish fw-bold text-primary">Let's talk</h1>
                <p className="lead mb-md-2">
                  We’re here to help and answer any question you might have. We'll do our best to get back to you right away!
                </p>
                <Form
                  id="contactForm"
                  noValidate
                  validated={validated}
                  onSubmit={processForm}
                  data-ad-element-type="contact form"
                  data-ad-text="Submit"
                  data-ad-button-location="body"
                  className="text-start"
                >
                  <Form.Group className="mb-1" controlId="formName">
                    <Row className="g-1">
                      <Col sm>
                        <Form.Label>First name<sup>*</sup></Form.Label>
                        <Form.Control
                          type="text"
                          required
                          onChange={e => setFnameValue(e.target.value)}
                          className="form-control-round"
                        />
                        <Form.Control.Feedback type="invalid" className="fst-italic fc-feedback text-white">
                          Please enter your first name
                        </Form.Control.Feedback>
                      </Col>
                      <Col sm>
                        <Form.Label>Last name<sup>*</sup></Form.Label>
                        <Form.Control
                          type="text"
                          required
                          onChange={e => setLnameValue(e.target.value)}
                          className="form-control-round"
                        />
                        <Form.Control.Feedback type="invalid" className="fst-italic fc-feedback text-white">
                          Please enter your last name
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group controlId="formEmail" className="mb-1">
                    <Form.Label>Email address<sup>*</sup></Form.Label>
                    <Form.Control
                      required
                      type="email"
                      value={emailValue}
                      pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
                      onChange={e => setEmailValue(e.target.value)}
                      className="form-control-round"
                    />
                    <Form.Control.Feedback type="invalid" className="fst-italic fc-feedback text-white">
                      Please enter a valid email address
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-1" controlId="formMessage">
                    <Form.Label>Message<sup>*</sup></Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      rows={6}
                      onChange={e => setMsgValue(e.target.value)}
                      className="form-control-round"
                    />
                  </Form.Group>

                  <Button
                    variant="warning"
                    className="btn-lg"
                    type="submit"
                    disabled={formDisabled}
                    data-ad-element-type="contact form submit"
                    data-ad-text="Submit"
                    data-ad-button-location="body"
                    data-ad-button-id="version 1"
                  >
                    Submit
                  </Button>
                </Form>
                <Alert variant="success" className="mt-1" show={showAlert}>
                  Thanks! We'll be in touch.
                </Alert>
              </Card>
            </Col>

            <Col md={{ span: 5, order: 0 }} lg="4" xxl="3" className="bg-light text-primary">
              <div className="p-2 p-xl-3 p-xxl-4">
                <h2 className="mb-12">Sell meati™</h2>
                <p className="mb-1">Add deliciously juicy, ultra-tender meati™ cuts to your menu.</p>
                <ButtonLink btnLink="/sell" btnClass="btn-outline-primary mb-2" btnText="Partner With Us" />
                <h2 className="mb-1">Media inquiries</h2>

                <Obfuscate
                  email="press@meati.com"
                  obfuscateChildren={false}
                  linkText="Press inquiries"
                  className="btn btn-outline-primary ad-el-tracked mb-2"
                  data-ad-element-type="cta button"
                  data-ad-text="Press inquiries"
                  data-ad-button-id="version 1"
                  data-ad-button-location="body"
                  headers={{
                    subject: 'Press inquiry from Meati.com',
                  }}
                  aria-label="Press Inquiries"
                >
                  Press Inquiries
                </Obfuscate>
                <h2 className="mb-1">Connect</h2>
                <SocialIcons color="primary" classes="justify-content-center justify-content-sm-start" />
              </div>
            </Col>

          </Row>
        </Container>
      </header>
      <MainCTA />
    </Layout>
  )
}

export default ContactPage
